<template>
  <v-dialog
    :value="isVisible"
    max-width="500"
    transition="dialog-transition"
    @input="$emit('input', $event)"
    @keydown.esc="cancel"
    @click:outside="cancel"
  >
    <v-card>
      <v-card-title primary-title v-text="$t('personalInfoPage.switchAccount')"/>
      <v-card-text>
        <v-row>
          <v-col v-text="$t('personalInfoPage.AreYouSureToSwitchAccount')"/>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-btn dark @click="setAccount(accountById)" v-text="$t('personalInfoPage.yes')"/>
        <v-btn text @click="cancel" v-text="$t('personalInfoPage.cancel')"/>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import Vue from 'vue'

export default Vue.extend({
  name: "AccountChangeDialog",
  props: {
    isVisible: { type: Boolean, default: false},
    accountId: { type: String, required: true},
  },
  data() {
    return {
      loading: false,
    }
  },
  computed: {
    accountById() {
      return this.$auth.user().investment_accounts.find(acc => acc.id == this.accountId)
    },
  },
  methods: {
    cancel() {
      this.$emit('closeDialog')
    },
    setAccount(account) {
      this.$http.patch(`/auth/users/me/`, { last_selected_investment_account: account.id })
      this.$store.commit("setCurrentInvestmentAccount", account)
      this.$emit('closeDialog')
    },
  },
})
</script>
