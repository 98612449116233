<template>
  <v-dialog :value="isVisible" transition="dialog-transition" max-width="33rem" @keydown.esc="cancel">
    <v-card>
      <v-card-title v-text="$t('stripe.deleteAPaymentMethod')"/>
      <v-card-text class="pt-4">
        {{ $t('stripe.deleteCardConfirmation') }} {{ paymentCard.last4 }}?
      </v-card-text>
      <v-card-actions class="justify-end">
        <v-btn text @click="cancel" v-text="$t('common.cancel')"/>
        <v-btn
          color="red"
          text
          @click="deleteCard"
          v-text="'Delete'"
        />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>

import Vue from 'vue'

export default Vue.extend({
  name: 'DeletePaymentCard',
  props: {
    isVisible: { type: Boolean, default: false },
    paymentCard: { type: Object, default: null },
  },
  methods: {
    cancel() {
      this.$emit('closeDeleteCardDialog')
    },
    deleteCard() {
      this.loading = true
      this.$http.patch(`/payments/cards/${this.paymentCard.id}/`, { is_active: false })
        .then(() => {
          this.loading = false
          this.deleteCardModal = false
          this.$emit('closeDeleteCardDialog')
          this.$emit('cardDeleted')
          this.$auth.fetch()
        })
    },
  },
})
</script>
