<template>
  <Container :title="isCompanyAccount ? $t('personalInfoPage.companyInvestorAccountInfo') : $t('personalInfoPage.investorAccountInfo')" class="personal">
    <div class="inside-container">
      <v-row class="mb-8">
        <v-col cols="12">
          <v-menu offset-y content-class="elevation-1">
            <template #activator="{ on, attrs }">
              <v-btn v-bind="attrs" class="px-0" style="width: 100%" height="100%" v-on="on">
                <v-row align="stretch" class="mb-0" no-gutters>
                  <v-col cols="6">
                    <v-card
                      :class="$vuetify.theme.dark ? 'grey darken-4' : 'grey lighten-3'"
                      class="pa-6 text-center text-uppercase text-h6"
                      flat
                    >
                      <div v-text="$t('personalInfoPage.accountId')"/>
                    </v-card>
                  </v-col>
                  <v-col cols="6">
                    <v-card
                      class="pa-6 d-flex align-center justify-center text-center text-uppercase text-h6" height="100%"
                      flat
                      dark
                    >
                      <div>
                        <v-icon left large>mdi-chevron-down</v-icon>
                        {{ investmentAccount.reference_number }}
                      </div>
                    </v-card>
                  </v-col>
                </v-row>
              </v-btn>
            </template>
            <v-list>
              <v-list-item
                v-for="account in annotatedAccounts"
                :key="account.id"
                :class="account.id == investmentAccount.id ? 'active-account' : 'not-active-account'"
                link
                @click="setAccount(account.object)"
              >
                <v-list-item-title>
                  <v-tooltip bottom :color="account.onboarded ? 'success' : 'warning'">
                    <template #activator="{ on, attrs }">
                      <v-icon v-if="account.onboarded" color="success" v-bind="attrs" v-on="on">mdi-check-circle-outline</v-icon>
                      <v-icon v-else color="warning" v-bind="attrs" v-on="on">mdi-progress-alert</v-icon>
                    </template>
                    <span v-text="account.onboarded ? $t('header.investmentAccounts.accountIsReady') : $t('header.investmentAccounts.accountIsNotReady')"/>
                  </v-tooltip>
                  <span class="ml-4" v-text="getAccountName(account)"/>
                  <span class="grey--text"> ({{ account.reference_number }})</span>
                </v-list-item-title>
              </v-list-item>
              <v-divider/>
              <v-list-item to="/account/companies/create" link dense>
                <v-list-item-title class="text-center">
                  <v-icon>mdi-plus</v-icon>
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-col>
      </v-row>

      <v-card :loading="loadingCompany" flat>
        <v-list v-if="isCompanyAccount" class="user-info">
          <v-list-item v-for="row in rows.company" :key="row.key">
            <v-list-item-content>
              <v-col cols="12" md="3">
                <v-list-item-subtitle v-text="row.label"/>
              </v-col>
              <v-col cols="12" md="9">
                <v-list-item-title v-if="row.key == 'name' && !isCompanyAccount">{{ user.first_name }} {{ user.last_name }}</v-list-item-title>
                <v-list-item-title v-if="row.key == 'country'">
                  <BaseCountry :country="company.country" with-text no-tooltip/>
                </v-list-item-title>
                <v-list-item-title v-if="row.key == 'tax_country'">
                  <BaseCountry :country="company.country" with-text no-tooltip/>
                </v-list-item-title>
                <v-list-item-title v-else-if="row.key === 'files'" class="mt-n5">
                  <v-btn
                    v-for="file in company.registry_extract_files" :key="file.id"
                    color="lightgrey" :href="file.file" target="_blank" download
                    x-small class="mr-2 my-1 py-4"
                  >
                    <v-icon left>mdi-attachment</v-icon>
                    {{ toFilename(file) }}
                  </v-btn>
                </v-list-item-title>
                <v-list-item-title v-else-if="row.key === 'files_poa'" class="mt-n5">
                  <v-btn
                    v-for="file in company.right_of_representation_files" :key="file.id"
                    color="lightgrey" :href="file.file" target="_blank" download
                    x-small class="mr-2 my-1 py-4"
                  >
                    <v-icon left>mdi-attachment</v-icon>
                    {{ toFilename(file) }}
                  </v-btn>
                </v-list-item-title>
                <v-list-item-title v-else-if="row.key == 'activity'" v-text="$t(`economicActivities.${company[row.key]}`)"/>
                <v-list-item-title v-else>
                  <template v-if="isCompanyAccount">{{ company[row.key] }}</template>
                </v-list-item-title>
              </v-col>
            </v-list-item-content>
          </v-list-item>
        </v-list>
        <h3 class="title text-uppercase">{{ isCompanyAccount ? $t('personalInfoPage.representativeInfo') : $t('personalInfoPage.personalInfo') }}</h3>
        <UserInfoList :primary-rows="rows.primary" :secondary-rows="rows.secondary"/>
        <v-btn outlined to="/account/personal/edit">
          {{ isCompanyAccount ? $t('personalInfoPage.editRepresentativeInfo') : $t('personalInfoPage.editPersonalInfo') }}
        </v-btn>
      </v-card>

      <template v-if="isCompanyAccount">
        <v-divider class="mt-10 mb-8"/>
        <h3 class="title text-uppercase mb-6 mt-12">{{ $t('personalInfoPage.boardMembers') }}</h3>
        <v-card v-if="company.board_members.length" :loading="loadingCompany" flat>
          <v-simple-table>
            <template #default>
              <thead>
                <tr>
                  <th>{{ $t('tableHeaders.name') }}</th>
                  <th>{{ $t('personalInfoPage.nationalIdNumber') }}</th>
                  <th>{{ $t('tableHeaders.country') }}</th>
                  <th>{{ $t('personalInfoPage.canRepresent') }}</th>
                  <th width="40%">{{ $t('personalInfoPage.files') }}</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="boardMember in company.board_members" :key="boardMember.id">
                  <td>{{ boardMember.first_name }} {{ boardMember.last_name }}</td>
                  <td>{{ boardMember.national_id }}</td>
                  <td><BaseCountry :country="boardMember.country"/></td>
                  <td>{{ boardMember.can_represent ? $t('personalInfoPage.yes') : $t('personalInfoPage.no') }}</td>
                  <td>
                    <v-btn
                      v-for="file in boardMember.files_detailed" :key="file.id"
                      color="lightgrey" :href="file.file" target="_blank" download
                      x-small class="mr-2 my-1 py-4"
                    >
                      <v-icon left>mdi-attachment</v-icon>
                      {{ toFilename(file) }}
                    </v-btn>
                    <v-btn
                      v-for="file in boardMember.files_poa_detailed" :key="file.id"
                      color="lightgrey" :href="file.file" target="_blank" download
                      x-small class="mr-2 my-1 py-4"
                    >
                      <v-icon left>mdi-attachment</v-icon>
                      {{ toFilename(file) }}
                    </v-btn>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card>
        <v-card v-else flat>{{ $t('personalInfoPage.noBoardMembers') }}</v-card>

        <h3 class="title text-uppercase mb-6 mt-12">{{ $t('personalInfoPage.shareholders') }}</h3>
        <v-card v-if="company.shareholders.length" :loading="loadingCompany" flat>
          <v-simple-table>
            <template #default>
              <thead>
                <tr>
                  <th>{{ $t('personalInfoPage.name') }}</th>
                  <th>{{ $t('personalInfoPage.idCode') }}</th>
                  <th>{{ $t('personalInfoPage.country') }}</th>
                  <th>{{ $t('personalInfoPage.share') }}</th>
                  <th width="40%">{{ $t('personalInfoPage.files') }}</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="shareholder in company.shareholders" :key="shareholder.id">
                  <td v-if="shareholder.company_name">{{ shareholder.company_name }}</td>
                  <td v-else>{{ shareholder.first_name }} {{ shareholder.last_name }}</td>
                  <td v-if="shareholder.company_name">{{ shareholder.registration_number }}</td>
                  <td v-else>{{ shareholder.national_id }}</td>
                  <td><BaseCountry :country="shareholder.country"/></td>
                  <td>{{ shareholder.share }}</td>
                  <!--<td>{{ shareholder.is_exposed ? 'Yes' : 'No' }}</td>-->
                  <td>
                    <v-btn
                      v-for="file in shareholder.files_detailed" :key="file.id"
                      color="lightgrey" :href="file.file" target="_blank" download
                      x-small class="mr-2 my-1 py-4"
                    >
                      <v-icon left>mdi-attachment</v-icon>
                      {{ toFilename(file) }}
                    </v-btn>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card>
        <v-card v-else flat>{{ $t('personalInfoPage.noShareholders') }}</v-card>

        <h3 class="title text-uppercase mb-6 mt-12">{{ $t('personalInfoPage.beneficiaries') }}</h3>
        <v-card v-if="company.beneficiaries.length" :loading="loadingCompany" flat>
          <v-simple-table>
            <template #default>
              <thead>
                <tr>
                  <th>{{ $t('personalInfoPage.name') }}</th>
                  <th>{{ $t('personalInfoPage.idCode') }}</th>
                  <th>{{ $t('personalInfoPage.country') }}</th>
                  <th>{{ $t('personalInfoPage.share') }}</th>
                  <th width="40%">{{ $t('personalInfoPage.files') }}</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="beneficiary in company.beneficiaries" :key="beneficiary.id">
                  <td v-if="beneficiary.company_name">{{ beneficiary.company_name }}</td>
                  <td v-else>{{ beneficiary.first_name }} {{ beneficiary.last_name }}</td>
                  <td v-if="beneficiary.company_name">{{ beneficiary.registration_number }}</td>
                  <td v-else>{{ beneficiary.national_id }}</td>
                  <td><BaseCountry :country="beneficiary.country"/></td>
                  <td>{{ beneficiary.share }}</td>
                  <td>
                    <v-btn
                      v-for="file in beneficiary.files_detailed" :key="file.id"
                      color="lightgrey" :href="file.file" target="_blank" download
                      x-small class="mr-2 my-1 py-4"
                    >
                      <v-icon left>mdi-attachment</v-icon>
                      {{ toFilename(file) }}
                    </v-btn>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card>
        <v-card v-else flat>{{ $t('personalInfoPage.noBeneficiaries') }}</v-card>
        <v-btn class="mt-6" outlined to="/account/company/edit" v-text="$t('personalInfoPage.editCompanyInfo')"/>
      </template>

      <v-divider class="mt-10 mb-8"/>

      <template v-if="showStuff">
        <h3 class="title text-uppercase mb-6 mt-12">{{ $t('personalInfoPage.bankAccounts') }}</h3>
        <v-card v-if="bankAccounts.length" :loading="loadingBankAccounts" flat>
          <v-simple-table>
            <template #default>
              <thead>
                <tr>
                  <th>{{ $t('personalInfoPage.IBAN') }}</th>
                  <th>{{ $t('personalInfoPage.bankName') }}</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="bankAccount in bankAccounts" :key="bankAccount.id">
                  <td>{{ bankAccount.iban }}</td>
                  <td>{{ bankAccount.bank_name }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card>
        <v-card v-else flat>{{ $t('personalInfoPage.noBankAccounts') }}</v-card>
      </template>

      <template v-if="showStuff">
        <v-row class="mb-6 mt-12" no-gutters>
          <v-col cols="12">
            <h3 id="addNewCard" class="title text-uppercase">{{ $t('personalInfoPage.paymentCards') }}</h3>
            <p>
              {{ paymentCardsHint }}
            </p>
          </v-col>
        </v-row>
        <v-card v-if="activeCards.length" flat>
          <v-simple-table>
            <template #default>
              <thead>
                <tr>
                  <th>{{ $t('personalInfoPage.cardHolder') }}</th>
                  <th>{{ $t('personalInfoPage.cardNumber') }}</th>
                  <th>{{ $t('personalInfoPage.cardExpiry') }}</th>
                  <th>{{ $t('personalInfoPage.cardBrand') }}</th>
                  <th/>
                </tr>
              </thead>
              <tbody>
                <tr v-for="paymentCard in activeCards" :key="paymentCard.id">
                  <td>{{ paymentCard.cardholder_name }}</td>
                  <td>{{ paymentCard.last4 }}</td>
                  <td>{{ expFormat(paymentCard) }}</td>
                  <td>{{ paymentCard.brand }}</td>
                  <td>
                    <v-btn x-small class="ml-2 py-4" text @click="deleteCardDialog = true">
                      <v-icon>mdi-close</v-icon>
                    </v-btn>
                    <DeletePaymentCard :is-visible="deleteCardDialog" :payment-card="paymentCard" @closeDeleteCardDialog="closeDeleteCardDialog"/>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card>
        <v-card v-else flat>{{ $t('personalInfoPage.noPaymentCards') }}</v-card>
        <v-card class="mt-6" flat>
          <v-btn
            :to="{ name: 'AddNewCard', params: { back: true } }"
            class="my-3" dark
            elevation="0"
            :disabled="!VUE_APP_STRIPE_PUBLISHABLE_KEY"
          >
            {{ $t('personalInfoPage.addNewCard') }}
          </v-btn>
        </v-card>
      </template>

      <v-row class="mb-4">
        <v-col sm="8" md="7" lg="4" xl="3">
          <h3 class="title text-uppercase mb-2">{{ $t('personalInfoPage.investmentAccountsList') }}</h3>
          <v-radio-group v-model="selectedAccountForDialogId">
            <v-radio
              v-for="account in annotatedAccounts"
              :key="account.id"
              :value="account.id"
              @click="selectForChange(account)"
            >
              <template #label>
                <span :class="{'font-weight-black' : account.id === investmentAccount.id}" v-text="getAccountName(account)"/>
                <v-spacer/>
                <span class="grey--text">({{ account.reference_number }})</span>
              </template>
            </v-radio>
          </v-radio-group>
        </v-col>
      </v-row>
    </div>
    <AccountChangeDialog :is-visible="isAccountDialogVisible" :account-id="selectedAccountForDialogId" @closeDialog="closeDialog"/>
  </Container>
</template>

<script>

// Personal / Representative / Company info
// Yes, the same component for all three pages. Sue me.
import Vue from 'vue'
import {VUE_APP_STRIPE_PUBLISHABLE_KEY} from '../../../config'
import AccountChangeDialog from '../components/AccountChangeDialog.vue'
import UserInfoList from '../components/UserInfoList.vue'
import DeletePaymentCard from "@/base/components/DeletePaymentCard"

export default Vue.extend({
  name: "Personal",
  components: { DeletePaymentCard, UserInfoList, AccountChangeDialog },
  data() {
    return {
      VUE_APP_STRIPE_PUBLISHABLE_KEY,
      editPopup: false,
      documentValid: false,
      company: { board_members: [], shareholders: [], beneficiaries: [], files: [], files_poa: []},
      bankAccounts: [],
      loadingCompany: false,
      loadingBankAccounts: false,
      showAdditionalInfo: false,
      isAccountDialogVisible: false,
      selectedAccountForDialogId: "",
      deleteCardDialog: false,
    }
  },
  computed: {
    showStuff() {
      return this.investmentAccount.type != 'company' || this.isCompanyAccount
    },
    rows() {
      const rows = {
        primary: [
          { key: 'name',        label: this.$t('settingsPage.name') },
          { key: 'email',       label: this.$t('settingsPage.email') },
        ],
        secondary: [
          { key: 'birthdate',   label: this.$t('settingsPage.birthdate') },
          { key: 'phone',       label: this.$t('settingsPage.phone') },
          { key: 'address',     label: this.$t('settingsPage.address') },
          { key: 'national_id', label: this.$t('settingsPage.nationalIdNumber') },
          { key: 'country',     label: this.$t('settingsPage.countryOfResidence') },
          { key: 'tax_country', label: this.$t('settingsPage.countryOfTaxResidency') },
          { key: 'doc_country', label: this.$t('settingsPage.documentIssuingCountry') },
          { key: 'doc_num',     label: this.$t('settingsPage.documentNumber') },
          { key: 'is_exposed',  label: this.$t('settingsPage.politicallyExposedPerson') },
          { key: 'activity',    label: this.$t('settingsPage.fieldOfEconomicalActivity') },
        ],
        company: [
          { key: 'name',                label: this.$t('personalInfoPage.companyName') },
          { key: 'registration_number', label: this.$t('personalInfoPage.registrationNumber') },
          { key: 'email',               label: this.$t('personalInfoPage.email') },
          { key: 'phone',               label: this.$t('personalInfoPage.phone') },
          { key: 'address',             label: this.$t('personalInfoPage.address') },
          { key: 'country',             label: this.$t('personalInfoPage.country') },
          { key: 'tax_country',         label: this.$t('settingsPage.countryOfTaxResidency') },
          { key: 'activity',            label: this.$t('personalInfoPage.activity') },
          { key: 'files',               label: this.$t('personalInfoPage.copyOfCommercialRegistryExtract') },
        ]}
      if (this.company.right_of_representation_files?.length > 0) {
        rows.company.push({ key: 'files_poa', label: this.$t('personalInfoPage.companyRightOfRepresentation') })
      }
      return rows
    },
    user() {
      return this.$auth.user()
    },
    investmentAccount() {
      return this.$store.state.account
    },
    paymentCardsHint() {
      return this.investmentAccount.type !== 'company' ?
        this.$t('personalInfoPage.hintForPrivateAccount') :
        this.$t('personalInfoPage.hintForCompany')
    },
    isCompanyAccount() {
      return (!!this.$store.state.account
           && !!this.$store.state.account.company)
    },
    accounts() {
      return this.user.investment_accounts
    },
    activeCards() {
      return this.investmentAccount.payment_cards.filter(card => card.is_active === true)
    },
    annotatedAccounts() {
      return this.accounts.map(account => ({
        ...account,
        object: account,
        onboarded: this.$auth.user().verification === 'approved' && account.is_approved,
      }))
    },
  },
  watch: {
    investmentAccount: {
      immediate: true,
      handler(value, oldValue) {
        if (value?.id && value?.id != oldValue?.id) {
          this.fetchBankAccounts()
          if (this.investmentAccount.type == 'company') { this.fetchCompany() }
          if (this.investmentAccount.type != 'company' && this.isCompanyAccount && this.$route.name === 'Company') {
            // This kind of page doesn't exist for non-company
            // accounts, so redirect to a different page instead.
            this.$router.push({name: "Personal"})
          }
          this.selectedAccountForDialogId = this.investmentAccount.id
        }
      },
    },
  },
  mounted() {
    this.selectedAccountForDialogId = this.investmentAccount.id
  },
  activated() {
    if (this.investmentAccount.type == 'company') { this.fetchCompany() }
  },
  methods: {
    expMonth(d) {
      if (d < 10) {
        return `0${d}`
      }
      return d
    },
    expYear(d) {
      return `${d}`.slice(2, 4)
    },
    expFormat(card) {
      return `${this.expMonth(card.exp_month)}/${this.expYear(card.exp_year)}`
    },
    fetchCompany() {
      if (this.investmentAccount.company?.id) {
        this.loadingCompany = true
        this.$http.get(`/companies/${this.investmentAccount.company.id}/`)
          .then((response) => {
            this.company = response.data
            this.loadingCompany = false
          })
          .catch(() => {
            this.loadingCompany = false
          })
      }
    },
    fetchBankAccounts() {
      // TODO pagination
      this.loadingBankAccounts = true
      this.$http
        .get(
          `/investment_accounts/${this.investmentAccount.id}/bank_accounts/`,
        )
        .then((accounts) => {
          this.loadingBankAccounts = false
          this.bankAccounts = accounts.data.results
        })
        .catch(() => {
          this.loadingBankAccounts = false
        })
    },
    toFilename(file) {
      return file.file.split('/').pop().split('?')[0]
    },
    setAccount(account) {
      this.$http.patch(`/auth/users/me/`, { last_selected_investment_account: account.id })
      this.$store.commit("setCurrentInvestmentAccount", account)
    },
    getAccountName(investmentAccount) {
      if (investmentAccount.type === "private") {
        return `${this.$auth.user().first_name} ${this.$auth.user().last_name}`
      } else {
        return investmentAccount.company && investmentAccount.company.name || this.$t('header.company')
      }
    },
    selectForChange(account) {
      if (account.id === this.investmentAccount.id) {
        this.$store.commit('pushMessage', {
          icon: 'mdi-robot-dead',
          text: this.$t('personalInfoPage.accountAlreadySelected'),
        })
      } else {
        this.selectedAccountForDialogId = account.id
        this.isAccountDialogVisible = true
      }
    },
    closeDeleteCardDialog() {
      this.deleteCardDialog = false
    },
    closeDialog() {
      this.isAccountDialogVisible = false
      this.selectedAccountForDialogId = this.investmentAccount.id
    },
  },
})
</script>
