<template>
  <v-list class="user-info">
    <v-list-item v-for="row in primaryRows" :key="row.key">
      <v-list-item-content>
        <v-col cols="12" md="4">
          <v-list-item-subtitle v-text="row.label"/>
        </v-col>
        <v-col cols="12" md="8">
          <v-list-item-title v-if="row.key == 'name'">
            {{ user.first_name }} {{ user.last_name }}
          </v-list-item-title>
          <v-list-item-title v-else-if="row.key == 'password'" class="button-row">
            <v-btn dark
                   :loading="loadingPasswordReset"
                   @click="resetPassword()"
            >
              {{ $t('settingsPage.resetPassword') }}
            </v-btn>
          </v-list-item-title>
          <v-list-item-title v-else v-text="user[row.key]"/>
        </v-col>
      </v-list-item-content>
    </v-list-item>

    <v-list-item v-for="row in secondaryRows" :key="row.key">
      <v-list-item-content>
        <v-col cols="12" md="4">
          <v-list-item-subtitle v-text="row.label"/>
        </v-col>
        <v-col cols="12" md="8">
          <v-list-item-title v-if="row.key == 'country'">
            <BaseCountry :country="user.country" with-text no-tooltip/>
          </v-list-item-title>
          <v-list-item-title v-else-if="row.key == 'tax_country'">
            <BaseCountry :country="user.tax_country" with-text no-tooltip/>
          </v-list-item-title>
          <v-list-item-title v-else-if="row.key == 'doc_country'">
            <BaseCountry :country="user.doc_country" with-text no-tooltip/>
          </v-list-item-title>
          <v-list-item-title v-else-if="row.key == 'birthdate'">
            <BaseDate :date="user.birthdate "/>
          </v-list-item-title>
          <v-list-item-title v-else-if="row.key == 'is_exposed'">
            {{ user.is_exposed ? $t('settingsPage.yes') : $t('settingsPage.no') }}
          </v-list-item-title>
          <v-list-item-title v-else-if="row.key == 'activity'" v-text="$t(`economicActivities.${user[row.key]}`)"/>
          <v-list-item-title v-else v-text="user[row.key]"/>
        </v-col>
      </v-list-item-content>
    </v-list-item>
  </v-list>
</template>

<script>
import Vue from 'vue'

export default Vue.extend({
  name: "UserInfoList",
  props: {
    primaryRows: { type: Array, required: true },
    secondaryRows: { type: Array, default: () => { return [] } },
  },
  data() {
    return {
      loadingPasswordReset: false,
    }
  },
  computed: {
    user() {
      return this.$auth.user()
    },
  },
  methods: {
    resetPassword() {
      this.loadingPasswordReset = true
      this.$http
        .post(`/auth/users/reset_password/`, { email: this.user.email })
        .then(() => {
          this.loadingPasswordReset = false
          this.$store.commit('pushMessage', {
            icon: 'mdi-check',
            text: this.$t('infoMessages.newPasswordHasBeenSent'),
          })
        })
        .catch(() => {
          this.loadingPasswordReset = false
        })
    },
  },
})
</script>
